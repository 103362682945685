<template>
  <div class="story-box">
    <proTitle></proTitle>
    <div class="story-cont" v-if="list && list.length > 0">
      <div>
        <div class="item csp" v-for="(item, index) in list" :key="index"
          @click="$router.push(`/news/detail/${item.id}?breadNumber=4`)">
          <div class="img-box">
            <img v-if="item.cover" :src="item.cover" alt="图片未显示" />
          </div>
          <div class="cont-box flex-between">
            <div class="title wes-2"> {{ item.title }} </div>
            <div>
              <p class="common">{{ item.redactor }}</p>
              <p class="time flex-align-between">
                <span>发布于：{{ item.createTime }}</span>
                <span><i class="el-icon-view"></i> {{ item.views }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
        :limit.sync="pages.pageSize" @pagination="handlePageChange">
      </pagination>
    </div>
    <Empty :show="list && list.length == 0"></Empty>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        list: [],
        pages: { //分页
          currentPage: 1,
          pagesize: 6,
          total: 0,
        },
      };
    },
    watch: {},
    mounted() {
      this.getTeacherList();
    },
    methods: {
      // 翻页
      handlePageChange(data) {
        this.pages.currentPage = data.pageIndex
        this.pages.pageSize = data.pageSize
        this.getTeacherList();
      },
      async getTeacherList() {
        let params = {
          page: this.pages.currentPage,
          limit: this.pages.pagesize,
          secondId: this.$store.state.user.currentProject.id
        };
        let resData = await this.$Api.DST.getlistDst(params);
        if (resData.code == 200) {
          this.list = resData.data.list;
          this.pages.total = resData.data.totalCount
        } else {
          this.$message.error(resData.msg);
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .story-box {
    padding: 25px 40px;
    border-radius: 10px;
    margin: 0 auto 297px;

    .story-cont {


      .item {
        display: flex;
        border-bottom: 1px solid #F5F5F5;
        padding: 32px 0;

        .img-box {
          img {
            width: 218px;
            height: 136px;
            border-radius: 5px;
          }
        }

        &:last-child {
          border: 0;
        }
      }


    }

    .cont-box {
      flex: 1;
      padding-left: 20px;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 17px;
        font-weight: 500;
        color: #242424;
      }

      .common {
        font-size: 13px;
        color: #999999;
        padding-bottom: 13px;
      }

      .time {
        position: relative;
        font-size: 13px;
        font-weight: 400;
        color: #999999;

        // .icon3 {
        //   position: absolute;
        //   right: 0;
        // }
      }
    }

    .pagination {
      text-align: center;
      margin: 0 0 30px;
    }
  }
</style>